<template>
  <div class="aovivo">
    <Voltar />
    <h1 class="titulo">Ao Vivo</h1>
    <div class="home__conteudo--item aovivo">
      <span>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="enable-background: new 0 0 100 100"
          xml:space="preserve"
        >
          <g style="display: none">
            <g style="display: inline">
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="71.097"
                y1="-121.497"
                x2="71.097"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="0"
                y1="-121.497"
                x2="0"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="15.798"
                y1="-121.497"
                x2="15.798"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="7.902"
                y1="-121.497"
                x2="7.902"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="23.701"
                y1="-121.497"
                x2="23.701"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="31.604"
                y1="-121.497"
                x2="31.604"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="39.5"
                y1="-121.497"
                x2="39.5"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="47.403"
                y1="-121.497"
                x2="47.403"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="55.298"
                y1="-121.497"
                x2="55.298"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="63.201"
                y1="-121.497"
                x2="63.201"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="78.999"
                y1="-121.497"
                x2="78.999"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="86.895"
                y1="-121.497"
                x2="86.895"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="94.798"
                y1="-121.497"
                x2="94.798"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="102.699"
                y1="-121.497"
                x2="102.699"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="110.597"
                y1="-121.497"
                x2="110.597"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-121.497"
                x2="118.498"
                y2="-3"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-121.497"
                x2="0"
                y2="-121.497"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-113.594"
                x2="0"
                y2="-113.594"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-105.698"
                x2="0"
                y2="-105.698"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-97.796"
                x2="0"
                y2="-97.796"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-89.901"
                x2="0"
                y2="-89.901"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-81.998"
                x2="0"
                y2="-81.998"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-74.094"
                x2="0"
                y2="-74.094"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-66.199"
                x2="0"
                y2="-66.199"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-58.298"
                x2="0"
                y2="-58.298"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-50.401"
                x2="0"
                y2="-50.401"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-42.5"
                x2="0"
                y2="-42.5"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-34.603"
                x2="0"
                y2="-34.603"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-26.701"
                x2="0"
                y2="-26.701"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-18.798"
                x2="0"
                y2="-18.798"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-10.903"
                x2="0"
                y2="-10.903"
              />
              <line
                style="
                  fill: none;
                  stroke: #00aeef;
                  stroke-width: 3;
                  stroke-miterlimit: 10;
                "
                x1="118.498"
                y1="-3"
                x2="0"
                y2="-3"
              />
            </g>

            <line
              style="
                display: inline;
                fill: none;
                stroke: #ed1c24;
                stroke-width: 3;
                stroke-miterlimit: 10;
              "
              x1="0"
              y1="-113.594"
              x2="118.498"
              y2="-113.594"
            />

            <line
              style="
                display: inline;
                fill: none;
                stroke: #ed1c24;
                stroke-width: 3;
                stroke-miterlimit: 10;
              "
              x1="0"
              y1="-10.903"
              x2="118.498"
              y2="-10.903"
            />
          </g>
          <line
            style="
              display: none;
              fill: none;
              stroke: #ed1c24;
              stroke-width: 3;
              stroke-miterlimit: 10;
            "
            x1="15.798"
            y1="-10.903"
            x2="15.798"
            y2="-113.594"
          />
          <line
            style="
              display: none;
              fill: none;
              stroke: #ed1c24;
              stroke-width: 3;
              stroke-miterlimit: 10;
            "
            x1="102.699"
            y1="-10.903"
            x2="102.699"
            y2="-113.594"
          />
          <g>
            <line
              style="
                fill: none;
                stroke: #fff;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 10;
              "
              x1="49.244"
              y1="55.369"
              x2="49.244"
              y2="93.401"
            />

            <ellipse
              style="
                fill: none;
                stroke: #fff;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 10;
              "
              cx="49.249"
              cy="47.971"
              rx="6.035"
              ry="6.033"
            />
            <path
              style="
                fill: none;
                stroke: #fff;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 10;
              "
              d="
                                    M18.104,72.171c-5.581-6.887-8.927-15.663-8.927-25.22c0-22.13,17.942-40.075,40.072-40.075S89.32,24.821,89.32,46.951
                                    c0,9.553-3.337,18.316-8.917,25.202"
            />
            <path
              style="
                fill: none;
                stroke: #fff;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 10;
              "
              d="
                                    M29.219,56.863c-1.13-2.633-1.758-5.534-1.758-8.585c0-12.031,9.753-21.784,21.783-21.784c12.038,0,21.789,9.753,21.789,21.784
                                    c0,3.252-0.71,6.336-1.986,9.105"
            />
          </g>
        </svg>
        Ao vivo na Rede America
      </span>

      <div v-if="!user.assinatura" class="exlusivo-assinante" style="margin-top: 1.5em; height: 420px; background-color: #fff">
      </div>
      <div v-if="user.assinatura" class="" style="margin-top: 1.5em;">
        <iframe
          src="https://player.twitch.tv/?channel=redeamericaetelevisao&parent=portalamericapremios.wserp.com.br"
          frameborder="0"
          allowfullscreen="true"
          scrolling="no"
          height="420"
          width="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))

    return {
      user
    }
  },
  components: { Voltar }
}
</script>
